// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-charities-js": () => import("../src/pages/charities.js" /* webpackChunkName: "component---src-pages-charities-js" */),
  "component---src-pages-charity-add-js": () => import("../src/pages/charity-add.js" /* webpackChunkName: "component---src-pages-charity-add-js" */),
  "component---src-pages-charity-js": () => import("../src/pages/charity.js" /* webpackChunkName: "component---src-pages-charity-js" */),
  "component---src-pages-donation-report-js": () => import("../src/pages/donation-report.js" /* webpackChunkName: "component---src-pages-donation-report-js" */),
  "component---src-pages-donation-reports-js": () => import("../src/pages/donation-reports.js" /* webpackChunkName: "component---src-pages-donation-reports-js" */),
  "component---src-pages-donation-js": () => import("../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-donations-js": () => import("../src/pages/donations.js" /* webpackChunkName: "component---src-pages-donations-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signin-js": () => import("../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signout-js": () => import("../src/pages/signout.js" /* webpackChunkName: "component---src-pages-signout-js" */)
}

